<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Project Name</th>
          <th>Project Code</th>
          <th>Party Name</th>
          <th>Business Line</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <td>Project</td>
        <td>#f4545</td>
        <td>Party</td>
        <td></td>
        <td>14/4/22</td>
        <td>17/5/22</td>
        <td>Active</td>
        <td>View</td>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup></script>
